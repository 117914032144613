<template>
<div id="summary" :class="($route.query.is_done == 1 ? 'is_done' : '')" v-if="loaded && (isShare || is_logged())">
   
   
    <md-dialog-confirm :md-active.sync="showTerms"
       md-confirm-text="מאשר"
      md-cancel-text="ביטול"
      @md-cancel="terms=false; showTerms=false;"
      @md-confirm="terms=true; showTerms=false;"
      :md-content="get_word('terms_of_condations')"
    >
    </md-dialog-confirm>



    <md-dialog :md-active.sync="show_contact_form">
      <md-dialog-title>חיזרו אלי בנוגע להזמנה זו</md-dialog-title>
        
        <div style="padding:0 25px">

            
     <form id="form" @submit.prevent="sendEmailForm" autocomplete="on">
        <md-field>
            <label>שם מלא</label>
            <md-input name="name" v-model="contact_form_data.name" required></md-input>
        </md-field>
        <md-field>
            <label>מספר פלאפון לחזרה</label>
            <md-input name="phone" v-model="contact_form_data.phone" required></md-input>
        </md-field>
        <md-field>
            <label>אימייל לקבלת עותק הזמנה</label>
            <md-input type="email" name="email" v-model="contact_form_data.email"></md-input>
        </md-field> 
     </form>
    </div>

      <md-dialog-actions>
        <md-button class="md-primary" @click="show_contact_form = false">סגירה</md-button>
        <md-button class="md-primary" @click="sendContactForm">שלח מייל</md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active.sync="showWhatsapp" class="showWhatsapp">
      <md-dialog-title>המספר שאליו תשלח ההודעה: </md-dialog-title>
        <div style="padding:0 25px">
        <input type="text" name="phone" v-model="computedPhoneData"/>
        </div>
      <md-dialog-actions>
        <md-button class="md-primary"  @click="showWhatsapp = false"><a target="_blank" :href="'https://api.whatsapp.com/send?phone=' + computedPhone + '&text=' + order.share_url">שליחה</a></md-button>
        <md-button class="md-primary" @click="showWhatsapp = false">סגירה</md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active.sync="show_email_form">
      <md-dialog-title>שלח הצעה במייל</md-dialog-title>
        
        <div style="padding:0 25px">

            
     <form id="form" @submit.prevent="sendEmailForm" autocomplete="on">
        <md-field>
            <label>שם מלא</label>
            <md-input name="name" v-model="email_form_data.name" required></md-input>
        </md-field>
        <md-field>
            <label>דוא"ל</label>
            <md-input type="email" name="email" v-model="email_form_data.email" required></md-input>
        </md-field>

        
     </form>
        </div>

      <md-dialog-actions>
        <md-button class="md-primary" @click="show_email_form = false">סגירה</md-button>
        <md-button class="md-primary" @click="sendEmailForm">שלח מייל</md-button>
      </md-dialog-actions>
    </md-dialog>


<md-dialog-confirm
      :md-active.sync="show_shiping_alert"
      md-title="משלוח"
      md-content="שימו לב! המשלוח הינו לכניסה לבניין בלבד. ללא העלאת מדרגות או קומות."
      md-confirm-text="מאשר"
      md-cancel-text="לא מאשר, שנה לאיסוף עצמי"
      @md-cancel="confirm_ship = false;form.delivery_type='1'; save_summary()"
      @md-confirm="confirm_ship = true;form.delivery_type='3'; save_summary()" />
    <md-dialog-confirm
      :md-active.sync="show_prod_alert"
      md-title="לקוח יקר - שים לב מלאי הבמבוקים נגמר."
      md-content="ההזמנה תסופק עם לייסטים וחבקים מתאימים.
      אם אינך מעוניין - אנא צור קשר עם שירות הלקוחות לשינוי ההזמנה:
        1-800-706-706"
      md-confirm-text="אשר והמשך לתשלום"
      md-cancel-text="לשינוי ההזמנה"
      @md-cancel="show_prod_alert = false;"
      @md-confirm="is_prod_exist_approve = true;show_prod_alert = false; sendFrm()" />


   <div class="row">
        <div class="col-md-6 col-xs-12" id="right_side" >
            <div id="print">
            <h1 class="title2">סיכום הבחירה שלך</h1>
            <h3 style="font-size: 24px; margin-top: -15px; margin-bottom: 15px;">מספר הזמנה: {{order.id}}</h3>
            <h3 v-if="order.siblings && order.siblings.length > 0" style="font-size: 24px; margin-top: -15px; margin-bottom: 15px;">הזמנות מקושרות: <router-link v-for="sib in order.siblings" :key="sib" :to="sib">{{sib}}</router-link></h3>
            <ul id="first_details" v-if="order.width > 0 && order.door_location != '' || order.prev_x > 0">
                <label>דגם הסוכה</label>
                <li v-for="(desc,i) in order.desc" :key="i" v-text="desc"></li>
            </ul>
        
            <div id="summary_details">

                <template v-for="category in basket">
                    <ul v-if="category.items.length > 0" :class="(order.summary_check_parse.type == 'false' ? 'unactive' : '')" :key="category.id">
                    <!-- <div class="checkbox_wrap" style="position: absolute; right: -5px;" v-if="$route.query.share == '1' && order.is_paid == '1'"> -->
                    <!-- <label>
                        <input type="checkbox" class="filled-in" :checked="(order.summary_check_parse.type != 'false')" />
                        <span></span>
                    </label> -->
                    <!-- </div> -->
                    
                    <label v-if="canEditUnits"><span @click="toggle_active_prods(category)" :key="category.is_active"><i :class="'fas '+(category.is_active ? 'fa-minus-circle' : 'fa-plus-circle')" ></i></span> {{category.name}}</label>
                    <label v-else>{{category.name}}</label>
                    <template v-for="item in category.items" >
                        <li :key="item.id" v-if="item.units > 0" :class="(item.is_active == '0' ? 'hidden' : '')">
                            <div v-if="canEditUnits" style="display:inline" v-show="!item.edit"><a class="edit" @click="item.edit=true; open_edit(item)"><i class="far fa-edit"></i></a>{{item.units}}</div>
                            <div v-if="canEditUnits" style="display:inline" v-show="item.edit"><a class="edit" @click="item.edit=false;"><i class="far fa-check-circle"></i></a><input type="number" v-model="item.units" :id="'inp_' + item.id" @blur="item.edit=false;" @input="save_summary"></div>
                            <div v-if="!canEditUnits" style="display:inline" v-text="item.units"></div>
                            
                            {{item.name}}<span >₪{{item.price * item.units}}</span>
                        </li>
                    </template>
                    <li :class="'active '+(category.is_active ? '' : 'hidden')">סה"כ {{category.name}}: <span>₪{{category_total(category)}}</span></li>
                    </ul>
                </template>

                <ul v-if="accessories.items.length > 0">
                    <label v-text="accessories.title"></label>
                    <template v-for="item in accessories.items" >
                        <li :key="item.id" v-if="item.units > 0">
                            <div v-if="canEditUnits" style="display:inline" v-show="!item.edit"><a class="edit" @click="item.edit=true; open_edit(item, true)"><i class="far fa-edit"></i></a>{{item.units}}</div>
                            <div v-if="canEditUnits" style="display:inline" v-show="item.edit"><a class="edit" @click="item.edit=false;"><i class="far fa-check-circle"></i></a><input type="number" v-model="item.units" :id="'inp_shop_' + item.id" @blur="item.edit=false;" @input="save_shop(item)"></div>
                            <div v-if="!canEditUnits" style="display:inline" v-text="item.units"></div>&nbsp;
                            <div class="summary_name">{{item.name}}</div> <span class="summary_price">₪{{item.price * item.units}}</span>
                        </li>
                    </template>
                    <li class="active">סה"כ {{accessories.title}}: <span>₪{{category_total(accessories)}}</span></li>
                </ul>

            </div>
            <div class="clr"></div>

            <div>
                <a @click="show_add_item=true" v-if="!show_add_item && canEditUnits">+ הוספת מוצר כללי</a>
                <div v-if="show_add_item" id="add_item_form">
                    <md-field>
                        <label>שם המוצר</label>
                        <md-input v-model="add_item_form.name"></md-input>
                    </md-field>
                    <md-field>
                        <label>מחיר</label>
                        <md-input v-model="add_item_form.price"></md-input>
                    </md-field>
                    <a @click="add_item()"><i class="fas fa-plus-circle"></i></a>
                    <a @click="show_add_item=false"><i class="fas fa-times-circle"></i></a>
                </div>
            </div>

            <!-- USER COUPON -->
            <div>
                <div v-if="order.discount_amount > 0 && userType != 2">
                    <b>הנחה</b>: 
                    <span style="margin-left:5px;" v-text="order.discount_amount + (order.discount_is_percent == '1' ? '%' : '₪')"></span> 

                    <span v-text="order.discount_description" style="margin-left:10px"></span> 
                    <a v-if="!isPaid" @click="remove_coupon" class="link" style="margin-left:10px">הסר</a>
                    <a v-if="!isPaid" @click="order.discount_amount=0" class="link">שינוי</a>
                </div>
             
                <div v-else-if="!isPaid && can_use_coupon ">
                    <a class="button" v-if="!show_coupon_form && userType != 2" @click="show_coupon_form=true">יש לך קופון?</a>
                    <div v-if="show_coupon_form" class="coupon_wrapper">
                    <md-field>
                        <label>קוד קופון</label>
                        <md-input v-model="couponCode"></md-input>
                    </md-field>
                    <a @click="applyCoupon">הפעל קופון</a>
                    </div>
                </div>
                <div v-if="order.discounts && order.discounts.length > 0 && userType != 2 ">
                    <b>שובר </b>: 
                    <div v-for="(discount,i) in order.discounts" :key="i">
                        <span v-text="discount.discount_description"></span> - 
                        <span style="margin-left:15px;" v-text="discount.discount_amount +  '₪'"></span>
                        <a v-if="!isPaid" @click="removePaymentCode(discount,i)" class="link" style="margin-right:10px">הסר</a>
                    </div>
                </div>
                 <div v-if="!isPaid && can_use_payment">
                    <a class="button" v-if="!show_payment_method_form && is_logged() && (allowPayment || userType == 1) && this.get_me().id !== '104' && this.get_me().id !== '99'" @click="show_payment_method_form=true">יש לך שובר?</a>
                    <div v-if="show_payment_method_form" class="coupon_wrapper">
                    <md-field>
                        <label>קוד </label>
                        <md-input v-model="paymentCode"></md-input>
                    </md-field>
                    <a @click="applyPaymentMethod">הפעל קוד</a>
                    </div>
                </div>
            </div>

            <!-- ADMIN COUPON -->
            <div id="discount" v-if="!isPaid && is_logged() && canEditUnits">
                <label :class="(order.coupon == 0 ? 'unactive' : '')">
                <input type="checkbox" class="filled-in" id="check_box_discount" v-model="coupon"/>
                <span id="check_box_discount_name">אחר</span>
                
                </label>
                <div v-if="coupon">

                    <md-field>
                        <label>בחר מהרשימה</label>
                        <md-select v-model="form.discount_id" @md-selected="selectedDiscountChange(); save_summary()">
                            <template v-if="userType != 2"><md-option v-for="discount in discounts" :value="discount.id" :key="discount.id">{{discount.name}}</md-option></template>
                            <md-option value="-1">אחר</md-option>    
                        </md-select>
                    </md-field>

                    <div v-if="form.discount_id != 0">
                        <div  v-if="form.discount_id == -1">
                            <md-field>
                                <label>תיאור</label>
                                <md-input v-model="form.discount_description"></md-input>
                            </md-field>
                        </div>

                        <div v-if="form.discount_id != -1">
                            <div>הנחה: 
                                <span v-text="form.discount_amount"></span>

                                <span v-if="!form.discount_is_percent">₪</span>
                                <span v-if="form.discount_is_percent">%</span>
                            </div>
                        </div>
                        
                        <md-field  v-if="form.discount_id == -1">
                            <label>הזן הנחה (באחוזים או בשקלים)</label>
                            <md-input v-model="form.discount_amount" @input="save_summary"></md-input>

                            <div class="discount_type">
                                <span :class="(!form.discount_is_percent ? 'active' : '')" @click="form.discount_is_percent=false; save_summary()">₪</span>
                                <span :class="(form.discount_is_percent ? 'active' : '')" @click="form.discount_is_percent=true;  save_summary()">%</span>
                            </div>

                        </md-field>
                    </div>
                </div>
            </div>
            
            <!-- Order Additions -->
                <div v-if="additions" id="additions_wrapper">
                    <div class="title_small">שינויים בהזמנה</div>
                    <div class="ord_title" v-html="additions.title + ' ' + additions.description"></div>
                    <label>פירוט:</label>
                    <ul>
                        <li v-for="(add,i) in additions.items" :key="i">
                            <div>הלקוח <b>{{ (add.refund == "1" ? "הוריד" : "הוסיף") }}</b>  {{ add.qty }} {{add.name}} <span dir="ltr" style="direction:ltr">   </span></div>
                        </li>
                    </ul>
                </div>
            <!-- Order Additions End -->

            <ul class="total">
    
                <template v-if="order.coupon != 0">
                    <template v-if="isShare || isPaid">
                        <label>{{order.coupon_reason}}:</label>
                        <li style="font-size: 22px; font-weight: bold;direction: rtl; margin-bottom: 25px;"><span style="direction: ltr">{{order.coupon}}</span>₪</li>
                    </template>
                </template>

                
                <label v-text="(order.is_expansion == '0' ? 'סוכה סהכ מחיר' : 'הרחבה סהכ מחיר')"></label>
                <li id="price"><p>₪</p>{{format_number(total_price)}}</li>
                
            </ul>  
            </div>

        </div> 
    
        <div class="col-md-6 col-xs-12" id="left_side">
                
            <h2 class="title2">מילוי פרטים ותשלום</h2>
        
            <form id="form" @submit.prevent="sendFrm" autoComplete="on" action="" method="post">
                <md-field>
                    <label>שם מלא</label>
                    <md-input name="name" v-model="form.name" autoComplete="on" @input="save_summary" required></md-input>
                </md-field>
                <md-field :class="{'md-invalid' : (!validatePhoneNumber() &&  form && form.phone && form.phone.length > 0)}">
                    <label>טלפון</label>
                    <md-input name="phone" v-model="form.phone" @input="save_summary" type="text" required></md-input>
                    <span class="md-error">נא להזין מספר טלפון תקין</span>
                </md-field>
                <md-field :class="{'md-invalid' : (!check_email && form && form.email && form.email.length > 0 )}">
                    <label>דוא"ל</label>
                    <md-input name="email" type="email" v-model="form.email" @input="save_summary" required></md-input>
                    <span class="md-error">נא להזין כתובת מייל תקינה</span>

                </md-field>
                <md-field>
                    <label>רחוב ומספר</label>
                    <md-input name="address" v-model="form.address" @input="save_summary" required></md-input>
                </md-field>
                <md-field>
                    <label>עיר</label>
                    <md-input name="address_city" v-model="form.address_city" @input="save_summary" required></md-input>
                </md-field>
                <md-field>
                    <label>הערות נוספות</label>
                    <md-input name="notes" v-model="form.notes" @input="save_summary"></md-input>
                </md-field>
                <md-field>
                    <label>איך הגעת אלינו?</label>
                    <md-select v-model="form.ref_source" @input="save_summary">
                    <md-option  v-for="ref in $store.ref_sources" :key="ref.id" :value="ref.id">{{ref.name}}</md-option>
                </md-select>

                    
                </md-field>
                <h3>שיטת משלוח</h3>
                <div style="font-size:14px;">הזמנות שיכנסו כעת יסופקו במשלוח תוך 14 ימי עסקים.</div>
                <template v-if="form.delivery_type != '5'">
                    <md-radio v-model="form.delivery_type" value="1" :disabled="isPaid" @change="shipingChange">איסוף עצמי</md-radio>
                    <md-radio v-model="form.delivery_type" value="3" :disabled="isPaid" @change="shipingChange">משלוח לכניסה לבניין - ₪{{delivery_price}}</md-radio>
                </template>
                <template v-else>
                    <md-radio v-model="form.delivery_type" value="5" >משלוח מצורף</md-radio>
                </template>
                <!-- <md-radio v-model="form.delivery_type" value="4" :disabled="isPaid" @change="shipingChange">משלוח אקספרס - ₪{{delivery_express_price}}</md-radio> -->
                <div v-if="form.delivery_type == '3' || form.delivery_type == '5' " style="font-size: 23px;text-align: center;font-weight: 600;color: red;">המשלוח הינו לכניסה לבניין בלבד!</div>


                <md-field  v-if="form.delivery_type == '1'">
                    <label>בחר אזור איסוף</label>
                    
                    <md-select v-model="form.delivery_type_area" @md-selected="save_summary">
                        <md-option v-for="typeItem in delivery_types" :key="typeItem.id" :value="typeItem.id">{{typeItem.name}}</md-option>    
                    </md-select>
                </md-field>

                <md-checkbox v-if="isShare" v-model="terms" >
                    <p style="font-size:17px;padding-right:30px"> אני מאשר/ת שקראתי ואני מסכימ/ה<a @click.prevent="showTerms=true" style="padding:5px; text-decoration:underline;">לתקנון ותנאי השימוש</a></p>

                </md-checkbox>
                


                <div class="bottom_area">
                    
                    <input v-if="!isPaid && allowPayment" :class="'button gotopaybtn ' + (isActiveSave ? '' : 'disabled')" :disabled="!isActiveSave" type="submit" value="מעבר לתשלום מאובטח ">
                </div>

                <div class="summaray_btns">
                    <input v-if="!isPaid && allowPayment" style="margin-bottom:15px; width:100%" :class="'button gotopaybtn ' + (isActiveSave ? '' : 'disabled')" :disabled="!isActiveSave" type="submit" value="מעבר לתשלום מאובטח ">
                    <a v-if="isShare" class="frmSbmBtn fancy order_btn green" id="send_phone_order" @click="show_contact_form=true"><i class="fas fa-phone-square-alt"></i>חיזרו אליי בקשר להזמנה זו</a>
                    
                    <div class="container-fluid">
                        <div class="col-md-6">
                            <router-link :to="'/print/' + order.id" class="frmSbmBtn order_btn " id="open"><i class="fas fa-print"></i>הדפס הצעה</router-link>
                        </div>
                        <div class="col-md-6">
                            <a class="frmSbmBtn fancy order_btn" id="send_sum" @click="show_email_form=true"><i class="far fa-paper-plane"></i>שלח למייל</a>
                        </div>
                        <div class="col-md-6" v-if="!$store.user || $store.user.id != '99'">
                            <a  class="frmSbmBtn normal_link order_btn" id="share_btn" target="_blank" :href="'https://api.whatsapp.com/send?text=' + order.share_url" data-action="share/whatsapp/share"><i class="fas fa-share-alt-square"></i> שתף בוואטסאפ</a>
                        </div>
                        <div v-else class="col-md-6">
                            <a  class="frmSbmBtn normal_link order_btn" id="share_btn" @click="showWhatsapp = true" data-action="share/whatsapp/share"><i class="fas fa-share-alt-square"></i> שתף בוואטסאפ</a>
                        </div>

                        <div class="col-md-6">
                            <a href="http://www.sukah.co.il/home/doc.aspx?mCatID=52&mode=s&icid=75" target="_blank" class="button active order_btn" id="btn_loaction">נק' מכירה באזורך</a>
                        </div>
                        <div class="col-md-6">
                            <router-link :to="'/print/' + order.id + '?kits=true'" class="" id="open"><i class="fas fa-print"></i>הדפס קיטים</router-link>
                        </div>
                        
                    </div>
                    <div class="clr"></div>
                </div>

            </form>
        </div>
 <!-- <div class="col-md-6 col-xs-12">
 <div class="seller_info">
   <h5>פרטי הזמנה-</h5>
   <span>שם המוכר/ת: </span><span>{{seller.display_name}}</span>
   <p></p>
   <span>מספר הזמנה: </span><span>{{order.id}}</span>
   <p></p>
   <span>תאריך הזמנה: </span><span>{{order.date_created}}</span>
  </div>
</div> -->

       
        <div class="title2 hide-on-small-only" style="text-align: center;">חג סוכות שמח</div>
        <!-- <div class="bottom_area" v-if="isShare">
            <a @click="$emit('updatePage', 'accessories')" class="back">חזרה</a>
            <a href="/"  class="frmSbmBtn button white">התחל מחדש</a> 
        </div> -->
    
        <a href="/" v-if="is_logged()"  class="frmSbmBtn button white" style="width:auto; display:inline-block;">התחל מחדש</a> 
    </div>
    <div class="clr"></div>
</div>
</template>

<script>
export default {
  components: {  },
    props:["order"],
    data(){
        return{
            loaded:false,
            computedPhoneData:null,
            saveTimer: false,
            can_use_coupon: true,
            can_use_payment: true,
            confirm_ship: false,
            showWhatsapp: false,
            delivery_price: 0,
            //order_data:[],
            motot:[],
            additions:[],
            comments:[],
            seller:[],
            discounts:[],
            terms: false,
            show_prod_alert: false,
            is_prod_exist_approve: false,
            show_coupon_form: false,
            show_payment_method_form: false,
            showTerms: false,
            couponCode: '',
            paymentCode: '',
            show_email_form: false,
            email_form_data: {
                order_id: this.order.id,
                name: '',
                email: ''
            },
            show_contact_form: false,
            contact_form_data: {
                order_id: this.order.id,
                name: '',
                email: '',
                phone: ''
            },
            form:{
                id: this.order.id,
                name:this.order.name,
                phone:this.order.phone,
                email:this.order.email,
                address:this.order.address,
                address_city:this.order.address_city,
                notes:this.order.notes,
                delivery_type_area: this.order.delivery_type_area,
                delivery_type: this.order.delivery_type,
                discount_id: this.order.discount_id,
                discount_amount: this.order.discount_amount,
                discount_is_percent: (this.order.discount_is_percent == '1'),
                discount_description: this.order.discount_description,
                discount_minimum: this.order.discount_minimum,
                ref_source: this.order.ref_source,
            },
            popups:[],
            summary:[],
            popups_confirm:true,
            accessories: [],
            basket:[],
            delivery_types: [],
            coupon: (this.order.discount_amount > 0),
            show_shiping_alert: false,
            show_add_item: false,
            add_item_form: {
                order_id: this.order.id,
                name: '',
                price: ''
            }
        }
    },
    mounted(){
        this.net_api({ action: 'get_unavilable',data:{ id: this.order.id}}, (data) => {
            if(data.data)
            {
                this.get_unavilable(data.data);
            }
        });

     
        this.net_api({action: 'get_all_popups', data: {}}, (data) => {
            this.popups = data.data;

        });

             
      //  this.$emit('updateSummary');
        this.refreshData();
        this.email_form_data.name = this.form.name;
        this.email_form_data.email = this.form.email;

  },
  computed:{
    computedPhone() {
            // Create a deep copy of the original phone data
            let phone = JSON.parse(JSON.stringify(this.computedPhoneData));

            // Remove any '+' or '-' from the phone number
            phone = phone.replace(/[+-]/g, '');

            // If the phone number starts with '05', replace it with '972'
            if (phone.startsWith('0')) {
                phone = phone.replace(/^0/, '972');
            }
            return phone;
        },
        computedPhonesec() {
            // Create a deep copy of the original phone data
            let phone = JSON.parse(JSON.stringify(this.form.phone));

            // Remove any '+' or '-' from the phone number
            phone = phone.replace(/[+-]/g, '');

            // If the phone number starts with '05', replace it with '972'
            if (phone.startsWith('0')) {
                phone = phone.replace(/^0/, '972');
            }
            return phone;
        },
      userType(){
          return this.$store.user.user_type;
      },
      allowPayment(){
          return (this.order.allow_payment == '1');
      },
      isPaid(){
          return (this.order.is_paid == '1');
      },

      isShare(){
          return (/*this.order.source == 'site' && */!this.is_logged())
      },

      canEditUnits(){
          return !(this.isShare || this.isPaid || this.$route.query.share == '1');
      },

      check_email(){
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(this.form.email).toLowerCase());
      },
      check_phone(){
        return (this.form && this.form.phone && this.form.phone.length >= 9 ? true : '');
      },
      check_delivery(){
        if (this.form.delivery_type == '1')
        {
            if (this.form.delivery_type_area == ''|| this.form.delivery_type_area == 0) return false;
        }
        return true;
      },
      isActiveSave(){
        
        return !(this.form.name == '' || this.form.phone == '' || this.form.delivery_type == '0' || this.form.email == '' || !this.check_email || !this.check_delivery);
      },
      total_price_before_discount(){

            let ttl = 0;
            for (let z in this.basket)
            {
                for (let i in this.basket[z].items)
                {
                    if (this.basket[z].items[i].is_active == '1')
                    {
                        ttl += this.basket[z].items[i].price * this.basket[z].items[i].units;
                    }
                }
            }
        
            // Accessories
            for (let i in this.accessories.items)
            {
                ttl += this.accessories.items[i].price * this.accessories.items[i].units;
            }
          return ttl;
      },
      total_priceOLD(){

            let ttl = this.total_price_before_discount;

            // DISCOUNT
            if (this.form.discount_amount > 0)
            {
                let discount = this.form.discount_amount;
                
                if (this.form.discount_is_percent)
                {
                    discount = ttl / 100 * this.form.discount_amount;
                } 

                if(this.isNull(discount)) discount = 0;
             
                ttl = ttl.toFixed(2) -  +discount.toFixed(2) ;
            }
            

            // DELIVERY
            if (this.form.delivery_type == 3) ttl += parseFloat(this.delivery_price);

            return ttl;
        },
        total_price() {
            let ttl = this.total_price_before_discount;

            // DISCOUNT
            if (this.form.discount_amount > 0) {
                let discount = this.form.discount_amount;

                if (this.form.discount_is_percent) {
                    discount = ttl * (this.form.discount_amount / 100); // Calculate discount as a percentage of total
                }

                if (isNaN(discount)) discount = 0; // Check if discount is NaN

                ttl -= discount; // Subtract discount from total
            }

            if(this.order.discounts && this.order.discounts.length > 0)
            {
                
                for (let i in this.order.discounts) {
                  ttl -= this.order.discounts[i].discount_amount;
                }
            }

            // DELIVERY
            if (this.form.delivery_type == 3) {
                ttl += parseFloat(this.delivery_price);
            }
            console.log(this.order);
            
            return ttl.toFixed(2); // Format total to 2 decimal places before returning
        },
    },
    methods:{
        validatePhoneNumber() {
            
            const israelPhoneNumberRegex = /^((\+972|972|0)(([23489]\d{7})|([57]\d{8})))$/;
            return israelPhoneNumberRegex.test(this.form.phone);
        },
         async showPopup(index = 0)
         {
            if (index < this.popups.length) {
                const result = await this.$swal.fire({
                    title: this.popups[index].title,
                    html: this.popups[index].description,
                    showDenyButton: true,
                    allowOutsideClick: false,
                    showCancelButton: false,
                    confirmButtonText: "אישור",
                    denyButtonText: `לא מאשר`
                });

                if (result.isConfirmed)
                {
                    this.popups_confirm = true;
                    return this.showPopup(index + 1);
                } 
                else if (result.isDenied) {
                    this.popups_confirm = false;
                    return;
                }
            }
            else return true;
        },
        refreshData(){
            
            let urlData = {id: this.order.id};
            if (!this.isNull(this.$route.query.token)) urlData.token = this.$route.query.token;

            this.net_api({action: 'summary', data: urlData}, (data) => {

                if (data.data == false)
                {
                    alert('הזמנה לא נמצאה');
                    return;
                }
                //this.order_data = data.data.data
                this.motot = data.data.motot
                this.additions = data.data.additions;
                this.seller = data.data.seller
                this.summary = data.data.summary
                this.delivery_types = data.data.delivery_types

                for (let i in data.data.basket)
                {
                    let is_active = true;
                    for (let z in data.data.basket[i].items)
                    {
                        data.data.basket[i].items[z].edit = false;
                        if (data.data.basket[i].items[z].is_active == '0') is_active = false;
                    }
                    data.data.basket[i].is_active = is_active;
                }

                this.basket = data.data.basket

                for (let z in data.data.accessories.items)
                {
                    data.data.accessories.items[z].edit = false;
                }
                this.accessories = data.data.accessories

                this.discounts = data.data.discounts;
                this.delivery_price = data.data.delivery_price;
                this.delivery_express_price = data.data.delivery_express_price;
                if(+this.order.discount_id > 0) this.can_use_payment = false;
                
                if(this.order.discounts && this.order.discounts.length > 0) this.can_use_coupon = false;
                
                
                this.loaded = true
            });



            this.computedPhoneData = this.form.phone;
        },
        remove_coupon()
        {
            this.net_api({ action: 'order/remove_coupon', data: { id: this.order.id} },  () => {
                   
                    this.form.discount_id= 0;
                    this.form.discount_amount = 0;
                    //this.form.discount_is_percent = (data.data.is_percent == '1');
                    this.form.discount_description = '';

                    this.order.discount_description = '';
                    this.order.discount_amount = 0;
                    //this.order.discount_is_percent = this.form.discount_is_percent;
                    this.order.discount_minimum = 0;
                    this.can_use_payment = true;
                    this.save_summary();
                    this.refreshData();            
                //this.order = data.data;
            });
        },
        removePaymentCode(discount,index)
        {
            this.net_api({ action: 'order/remove_payment_code', data:  discount },  () => {
                    this.order.discounts.splice(index, 1);
                    if(this.order.discounts.length == 0) this.can_use_coupon = true;
                    this.save_summary();
                    this.refreshData();            

            });
        },
        toggle_active_prods(cat)
        {
            if(cat.is_active)
            {
                 for(let i in cat.items)
                {
                    cat.items[i].is_active = 0;
                }
                cat.is_active = false
            }
            else{
                 for(let i in cat.items)
                {
                    cat.items[i].is_active = 1;
                }
                cat.is_active = true
            }
           
            this.save_summary();
        },
        sendEmailForm(){
            this.net_api({action: 'send_to_email', data: this.email_form_data});

            this.show_email_form = false;
        },

        sendContactForm(){
            this.net_api({action: 'send_phone_order', data: this.contact_form_data});

            this.show_contact_form = false;
        },


        open_edit(item, is_shop)
        {
            setTimeout(() => {
                document.getElementById('inp_' + (is_shop === true ? 'shop_' : '') + item.id).focus();    
            }, 50);
            
        },
        category_total(category)
        {
            let ttl = 0;
            for (let i in category.items)
            {
               
                 ttl += category.items[i].price * category.items[i].units;
                    
            }

            return ttl;
        },
        find_prod()
        {
            let arr = [121,122,42,43];
            for(let i in this.basket)
            {
                for(let z in this.basket[i].items)
                {
                    for(let p in arr)
                    {
                        if(this.basket[i].items[z].item_id == arr[p] && this.basket[i].items[z].is_active) 
                        {
                            return "exist";
                        }
                    }
                }
            }
            if(this.accessories && this.accessories.items && this.accessories.items.length > 0)
            {
                for(let a in this.accessories.items)
                {
                    
                    for(let s in arr)
                    {
                        if(this.accessories.items[a].id == arr[s]) 
                        {
                            
                            return "exist";
                        }
                    }
                }
            }
            
            
            return true;
        },
        async sendFrm(){





            if(this.total_price <= 0)
            {
                if(this.total_price < 0)
                {
                    let content = `שימו לב שהזנתם קוד אמצעי תשלום גדול מסכום הקנייה.
פעולה זו תבטל את היתרה שרכשתם בקוד אמצעי התשלום.
תוכלו להוסיף עוד מוצרים בעצמכם או יחד עם שירות לקוחות או לחילופין לוותר על היתרה.

לשאלות אנא צור קשר עם שירות הלקוחות: 1-800-706-706`;
                    const result = await this.$swal.fire({
                        title: 'שימו לב!',
                        html: content,
                        showDenyButton: true,
                        allowOutsideClick: false,
                        showCancelButton: false,
                        confirmButtonText: "אישור",
                        denyButtonText: `לא מאשר`
                    });

                    if(!result.isConfirmed) return false;
                }
                

                this.net_api({action: 'order/mark_order_as_save', data: {order_id: this.order.id}}, (data) => {
                    if(!data.data)
                    {
                        alert("העסקה נכשלה נא ליצור קשר עם שירות לקוחות");
                        return false;
                    } 
                    else{
                        if(data.data.status)
                        {
                            document.location.href = data.data.link;
                        }
                        else{
                            alert("העסקה נכשלה נא ליצור קשר עם שירות לקוחות");
                             return false;
                        }
                    }
                //     console.log(data);
                
                
            });

            }
            else{
             

           
               



            if(!this.confirm_ship && this.form.delivery_type == 3)
            {
                this.show_shiping_alert = true;
                return false;
            }

          


            // להחליף במבוקים בלייסטים
            // if(this.find_prod() == "exist" && !this.is_prod_exist_approve)
            // {
            //     this.show_prod_alert = true;
            //     return false;
            // }
            
            await this.showPopup();

            if(this.popups_confirm)
            {
                this.save_summary_do(()=> { this.$emit('updatePage', 'pay'); });
            }
            else return false;  
        }
        },
        print_page()
        {
            window.open( 'home/print_page/{$data.id}' , '_blank' , 'width=800px,height=700,margin-left=400px,top=200');
        },
        clear_timout(){
            // var prnt = $(this).closest('label');
                    
            //         if ($(this).is(':checked'))
            //         {
            //             prnt.removeClass('unactive');
            //         }
            //         else
            //         {
            //             prnt.addClass('unactive');
            //         }
    
        },

        save_shop(item){
            this.net_api({action: 'shop/add_item', data: {order_id: this.order.id,item_id:item.id,qty:item.units}});
        },

        save_summary()
        {
            clearTimeout(this.saveTimer);
            this.saveTimer = setTimeout(() => {
                this.save_summary_do();
            }, 600);
        },
        save_summary_do(func)
        {
            var data = this.form; //{ id: this.order.id, /*summary_check: {}, comments: this.comments*/ };
            
            if (this.isNull(func)) func= () => { };

            data.basket = [];
            for (let i in this.basket)
            {
                data.basket.push(...this.basket[i].items.map((item) => { return {id: item.id, units: item.units, is_active: item.is_active} } ));
            }

            //  $('#summary_details ul').each(function(){
            //     this.order.summary_check[$(this).data('type')] = !$(this).is('.unactive');
            //  });
            
                data.coupon = this.order.coupon;
                data.coupon_reason = this.order.coupon_reason;
            
            
                this.net_api({ action: 'order/save_summary', data: data},  (/*data*/) => {
                    func()
                    //this.order = data.data;
                });
                this.email_form_data.name = this.form.name;
                this.email_form_data.email = this.form.email;
        },
        selectedDiscountChange(){
            let selectedDiscount = {};
            for (let i in this.discounts)
            {
                if (this.discounts[i].id == this.form.discount_id)
                {
                    selectedDiscount = this.discounts[i];
                }
            }
            
            this.form.discount_amount = selectedDiscount.amount;
            this.form.discount_is_percent = (selectedDiscount.is_percent == '1');
            
            if (selectedDiscount.starting_from > this.total_price_before_discount) 
            {
                alert('ההנחה רק החל מ' + selectedDiscount.starting_from);
            }

        },
        shipingChange(){
            if (this.form.delivery_type == 3)
            {
                this.show_shiping_alert = true;
            }
            else this.save_summary();
        },
        async get_unavilable(item){
        
                  
            const result = await this.$swal.fire({
                title: 'שימו לב!',
                html: item.text,
                showDenyButton: true,
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: "אישור",
                denyButtonText: `לא מאשר`
            });
            if(!result.isConfirmed)
            {
                document.location.href = 'https://www.sukah.co.il/';
            }
            else{
                this.net_api({ action: 'approve_unavilable', data: {id: item.id}},  () => {

                });
            }
                

        
        },
        applyCoupon(){
            // CHECK COUPON VALIDITY
            this.net_api({ action: 'order/apply_coupon', data: { code: this.couponCode, id: this.order.id}},  (data) => {
                   
                   if (data.data == 'used')
                   {
                       this.alert('קופון זה נוצל כבר');
                       return;
                   }
                   if (data.data == 'used_minimum')
                   {
                       this.alert('קופון לא עומד במינימום הנדרש');
                       return;
                   }
                   if (data.data == 'exp')
                   {
                       this.alert('קופון זה פג תוקפו');
                       return;
                   }
                   else if (data.data == false)
                   {
                       this.alert('הקופון לא נמצא, אנא נסו שנית.');
                       return;
                   }
                   else if (data.data == 'already_used')
                   {
                       this.alert('לא ניתן להתשמש באותו קופון פעמיים!');
                       return;
                   }
                   else if (data.data == 'cant_per')
                   {
                       this.alert('לא ניתן להתשמש בקופון של אחוזים !');
                       return;
                   }
                   else
                   {
                        this.form.discount_id= data.data.id;
                        this.form.discount_amount = data.data.amount;
                        this.form.discount_is_percent = (data.data.is_percent == '1');
                        this.form.discount_description = data.data.name;
                        this.can_use_payment = false;

                        this.order.discount_description = this.form.discount_description;
                        this.order.discount_amount = this.form.discount_amount;
                        this.order.discount_is_percent = this.form.discount_is_percent;
                        this.order.discount_minimum = this.form.discount_minimum;
                        // this.couponCode = '';

                        if (data.data.is_payment_method == '0' && +data.data.amount > 0) this.alert('קיבלת הנחה של ' + this.form.discount_amount + (this.order.discount_is_percent == '1' ? '%' : '₪'));
                        else if(+data.data.amount > 0) this.alert('שילמת סכום של ' + this.form.discount_amount);
                        else
                        {
                            this.alert('הקופון הופעל בהצלחה!');
                            
                        }
                        this.refreshData();
                   }
                

                //this.order = data.data;
            });
        },
        applyPaymentMethod(){
            // CHECK COUPON VALIDITY
            this.net_api({ action: 'order/apply_coupon_new', data: { code: this.paymentCode, id: this.order.id}},  (data) => {
                   
                   if (data.data == 'used')
                   {
                       this.alert('קופון זה נוצל כבר');
                       return;
                   }
                   else if (data.data == false)
                   {
                       this.alert('הקופון לא נמצא, אנא נסו שנית.');
                       return;
                   }
                   else if (data.data == 'already_used')
                   {
                       this.alert('לא ניתן להתשמש באותו קופון פעמיים!');
                       return;
                   }
                   else if (data.data == 'cant_per')
                   {
                       this.alert('לא ניתן להתשמש בקופון של אחוזים !');
                       return;
                   }
                   else
                   {
                        

                       
                        this.paymentCode = '';
                        this.can_use_coupon = false;
                        this.order.discounts.push(data.data);


                         this.alert('שילמת סכום של ' + data.data.discount_amount);
                   }
                

                //this.order = data.data;
            });
        },

        sendEmail(){
            alert(4);
        },

        add_item(){

            this.net_api({action: 'shop/add_free_item', data: this.add_item_form }, () => { this.refreshData(); });

            this.add_item_form.name = '';
            this.add_item_form.price = '';
        }

    },

}
    
</script>
<style lang="scss">
#additions_wrapper{
    .title_small{font-weight: 700;font-size: 20px;text-decoration: underline;}
    label{    font-weight: 700; font-size: 19px;}
}
.order_btn{width: 100%;margin: 5px 0;}
.summary_name{display: inline-block;width: 75%;}
.coupon_wrapper {
    position: relative;
    a { position: absolute; left: 0px; top: 0px; font-weight: bold; line-height: 80px; padding-left: 15px; text-decoration: underline !important;}
}

#left_side .green{background: #5ea219;color: #fff;}
#left_side {
    .svg-inline--fa{font-size: 18px;margin-left: 10px;}    
}
#form{
    .md-field label{left: auto;right: 10px;font-size: 28px; font-weight: bold;}
    .md-field .md-input{padding-right: 10px;font-size: 18px;}
}
#discount{
    .md-field label{left: auto;right: 10px;font-size: 17px;}
    .md-field .md-input{padding-right: 10px;font-size: 18px;}
}

#add_item_form { gap: 5px; display: flex; align-items: center; }

#summary 
{
    ul { margin-bottom: 25px; }
    ul label { font-size: 24px;line-height: 26px; font-weight: bold; }
    ul li { display: block; line-height: 25px;; font-size: 19px; clear: both; }


    #summary_details ul li span { float: left;}
    .hidden{opacity: 0.4;}
    #summary_details ul li .edit { color: #5ea219; font-size: 17px; text-decoration: underline; margin-left: 10px; display: inline-block; vertical-align: middle; width: 20px; line-height: 30px; }
    #summary_details ul li input { width:50px; height: 30px; text-align: center; font-weight: bold; padding: 0px; }

    ul li.active {	background-color: #5EA219; color: #fff; font-weight: bold; padding-right: 3px; font-weight: bold; line-height: 30px;}
    ul li#price { font-size: 60px; margin-top: 15px;	font-weight: bold;}
    ul li#price p { font-size: 30px;	font-weight: bold; display: inline;}
    .back { float: right;}
	.bottom_area .frmSbmBtn { float: left; max-width: 135px;}
	#right_side {border-left: 1px solid #979797; }
	#btn_loaction { position: relative;display: block; font-size: 16px; font-weight: bold; min-width: 0px; height: 55px; line-height: 55px; background: rgba(94, 162, 25, 0.6) !important; }
    ul.unactive { opacity: 0.5; }
    
    #comments { border: 2px solid #5ea219; padding: 10px; height: 100px; background: #fff; }
    .summaray_btns {
        a { margin-bottom: 15px; font-size: 16px;}

    }

    .discount_type { 
        & { }
        span { display: inline-block; width:30px; height: 30px; text-align: center; cursor: pointer; }
        span.active { background: #5ea219; color: #fff; cursor: default; }
    }
}
@media only screen and (max-width: 800px) {
    #summary #right_side{border: none;}
}

.md-button-content { color: red;}
.md-primary .md-button-content { color:#000;}

.gotopaybtn { background: #5ea219 !important; color:#fff !important; border:none; 
&.disabled { background: #979797 !important; }
}
.showWhatsapp .md-dialog-fullscreen{padding: 30px;border-radius: 10px;}
.showWhatsapp .md-title{font-size: 22px;font-weight: 700;}
.showWhatsapp input{width: 100%; height: 50px;padding: 0 10px;}
.showWhatsapp .md-dialog-actions{justify-content: space-between;    padding: 0 50px;}
.showWhatsapp .md-button.md-theme-default.md-primary{font-size: 18px;}
</style>

